import { template as template_70ca036bf1cf41d68beb0a780a3a60d0 } from "@ember/template-compiler";
const WelcomeHeader = template_70ca036bf1cf41d68beb0a780a3a60d0(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
