import { template as template_10da02590c95469fbddf0fc60254d9db } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_10da02590c95469fbddf0fc60254d9db(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
