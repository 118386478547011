import { template as template_7a1b9336200a44c2ab308d9ec4a59730 } from "@ember/template-compiler";
const FKControlMenuContainer = template_7a1b9336200a44c2ab308d9ec4a59730(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
